import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import LinkedIN from "../icons/LinkedIN.js"

export default function Teacher({ 
    name, 
    image,
    bio,
    linkedin,
    ...others
  }) {

  let imageSrc = image
  if(!imageSrc) imageSrc = { localFile: "../../images/post-image.png", altText: "Placeholder Image"}
  const img = getImage(imageSrc.localFile)

  return (
    <div className="d-flex flex-column flex-md-row">
      <div className="packing pe-0 pe-md-6 pb-6 pb-md-0 tac">
        <GatsbyImage image={img} className="image-cropper overlap-brd-golden" imgClassName="profile-pic" objectFit="cover" alt={imageSrc.altText} />
      </div>
      <div className="packing c-white d-flex flex-column justify-content-center">
        <span className="ttu fs-body-small">Professora</span>
        <h3 className="h3 c-golden">{ name ? name : null}</h3>
        <p>{ bio ? bio : null }</p>
        <a href={ linkedin ? linkedin : null }>
          <LinkedIN width="32" height="32" />
        </a>
      </div>
    </div>
  ) 
}
