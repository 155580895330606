import * as React from "react"
import Seo from 'gatsby-plugin-wpgraphql-seo'
import { getImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"

import Page from "../elements/Page.js"
import Button from "../components/Button.js"
import Icon from "../elements/Icon.js"

import CursosContentBeneficios from "../icons/CursosContentBeneficios.js"
import CursosContentPerfil from "../icons/CursosContentPerfil.js"

import Teacher from "../elements/Teacher.js"
import Depoimento from "../elements/Depoimento.js"

import ContainerWithBg from "../helpers/ContainerWithBg.js"
import Container from "../helpers/Container.js"

export default function CursoPage(props){

  const { featuredImage, title, yoastData, content } = props.pageContext
  const image = getImage(featuredImage.localFile)

  const sobre = content.comunicacaoCursoConteudoSobre
  const icones = content.comunicacaoCursoConteudoIcones
  const beneficios = content.comunicacaoCursoConteudoBeneficios
  const paraQuem = content.comunicacaoCursoConteudoParaQuem
  const motivos = content.comunicacaoCursoConteudoMotivos
  const aprender = content.comunicacaoCursoConteudoAprender
  const professor = content.comunicacaoCursoConteudoProfessor

  return (
    <Page>
      <Seo 
        title={yoastData.title}
        post={yoastData} 
      />

      <Helmet>
        <script type="text/javascript" async src="https://static.hotmart.com/checkout/widget.min.js"></script>
        <style type="text/css" rel="stylesheet" href="https://static.hotmart.com/css/hotmart-fb.min.css"></style>
      </Helmet>

      <ContainerWithBg
        classBgName="bg-pattern bg-pattern-linear-darker bg-image-dynamic py-4 py-xl-0"
        className="container-md tac mh-27r line-lenght-large ai-c jc-c dfc c-white p-r z1 feature-image"
        style={{backgroundImage: `url(${image.images.fallback.src}`}}
      >
        <div>
          <span className="tag bg-color-golden c-purple m-2 c-p db ttu mb-2 bold">
            Curso
          </span>
        </div>
        <h1 className="h1 mb-0">{title}</h1>
      </ContainerWithBg>

      <Container className="container-fluid bg-color-purple-4 p-4 p-lg-8">
        <div className="col-size-720 tac">
          <h2 className="h2 c-purple pb-4">{sobre.comunicacaoCursoConteudoSobreTitulo}</h2>
          <p className="pb-4"
            dangerouslySetInnerHTML={{ __html: sobre.comunicacaoCursoConteudoSobreConteudo }}
          >
          </p>
          {!content.comunicacaoCursoConteudoPagamento &&
            <Button 
              to={sobre.comunicacaoCursoConteudoSobreUrl}
              className="tac c-purple round cta bg-color-purple-1 c-white"
            >
              { sobre.comunicacaoCursoConteudoSobreCta }
            </Button>
          }
          {content.comunicacaoCursoConteudoPagamento &&
            <a 
              className="tac c-purple round cta bg-color-purple-1 c-white hotmart-fb hotmart__button-checkout"
              onClick={()=>{}}
              href={content.comunicacaoCursoConteudoPagamento}
            >
              Comprar Agora
            </a>
          }
        </div>
      </Container>

      <Container className="py-6 sep-y">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            { icones &&
              icones.map((icone, index) => (
                <div className="col-12 col-sm-6 col-md-4 col-lg-3" key={`icon-${index}`}>
                  <Icon
                    type={icone.comunicacaoCursoConteudoIconesIcone}
                    title={icone.comunicacaoCursoConteudoIconesTitulo}
                    content={icone.comunicacaoCursoConteudoIconesConteudo}
                  />
                </div>
              ))
            }
          </div>
        </div>
      </Container>

      <Container className="container-fluid">
        <div className="row">
          <div className="col-lg-6 order-2 order-lg-1 p-4 p-lg-8">
            <div className="col-size-720">
              {/* beneficios */}
              <CursosContentBeneficios />
              <h2 className="h2 c-purple">{beneficios.comunicacaoCursoConteudoBeneficiosTitulo}</h2>
              <div dangerouslySetInnerHTML={{ 
                  __html: beneficios.comunicacaoCursoConteudoBeneficiosConteudo
                }} 
                className="list-default mb-8" 
              />

              {/* para quem */}
              <CursosContentPerfil />
              <h2 className="h2 c-purple">{paraQuem.comunicacaoCursoConteudoParaQuemTitulo}</h2>
              <div dangerouslySetInnerHTML={{ 
                  __html: paraQuem.comunicacaoCursoConteudoParaQuemDescricao
                }} 
                className="the-content" 
              />
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2 p-0 bg-color-purple-4 d-flex flex-column justify-content-center p-4 p-lg-8">
            <div className="col-size-720">
              {/* Motivos */}
              <h2 className="h2 c-purple">{motivos.comunicacaoCursoConteudoMotivosTitulo}</h2>
              <div dangerouslySetInnerHTML={{ 
                  __html: motivos.comunicacaoCursoConteudoMotivosConteudo
                }} 
                className="list-default" 
              />

              {/* Aprender */}
              <h2 className="h2 c-purple">{aprender.comunicacaoCursoConteudoAprenderTitulo}</h2>
              <div dangerouslySetInnerHTML={{ 
                  __html: aprender.comunicacaoCursoConteudoAprenderConteudo
                }} 
                className="list-default" 
              />
            </div>
          </div>
        </div>
      </Container>

      {/* Professor */}
      { professor &&
        professor.map((prof, index) => (
          <div className="bg-color-purple p-4 p-lg-8" key={prof.id}>
            
            <div className="col-size-720">
              <Teacher
                name={prof.equipeFields.comunicacaoEquipeNome}
                image={prof.equipeFields.comunicacaoEquipeFoto}
                bio={prof.equipeFields.comunicacaoEquipeDescritivo}
                linkedin={prof.equipeFields.comunicacaoEquipeLinkedin}
              />
            </div>

          </div>
        ))
      }

      {/* Depoimento */}
      { content.comunicacaoCursoConteudoDepoimentos &&
        <Container className="container-fluid c-white p-4 p-lg-8">
          <div className="row justify-content-center">
            
            {(content.comunicacaoCursoConteudoDepoimentoLinha1 || content.comunicacaoCursoConteudoDepoimentoLinha2) &&
              <h2 className="tac h2 c-purple mb-6">
                {content.comunicacaoCursoConteudoDepoimentoLinha1 && content.comunicacaoCursoConteudoDepoimentoLinha1}
                
                {content.comunicacaoCursoConteudoDepoimentoLinha1 && content.comunicacaoCursoConteudoDepoimentoLinha2 &&
                  <br />
                }
                {content.comunicacaoCursoConteudoDepoimentoLinha2 &&
                  <span className="h2 c-orange">{content.comunicacaoCursoConteudoDepoimentoLinha2}</span>
                }
              </h2>
            }
            

            { content.comunicacaoCursoConteudoDepoimentos &&
              content.comunicacaoCursoConteudoDepoimentos.map((dep, index) => (
                <Depoimento
                  key={`dep-${index}`}
                  text={dep.comunicacaoCursoConteudoDepoimentosDepoimento}
                  jobTitle={dep.comunicacaoCursoConteudoDepoimentosTitulo}
                  company={dep.comunicacaoCursoConteudoDepoimentosNome}
                  picture={dep.comunicacaoCursoConteudoDepoimentosFoto}
                  video={dep.comunicacaoCursoConteudoDepoimentosVideo}                  
                />
              ))
            }
          </div>
        </Container>
      }
    </Page>
  )
}