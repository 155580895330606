import * as React from "react"

export default function CursosContentBeneficios({ width = 96, height = 96, fill = '#B25E8B', ...props }) {
  return (
    <svg style={{ width: width, height: height }} viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M48.1064 30.4138C41.0038 30.4138 35.182 24.592 35.182 17.4894C35.0655 10.3868 40.8873 4.56494 48.1064 4.56494C55.209 4.56494 61.0308 10.3868 61.0308 17.4894C61.0308 24.592 55.209 30.4138 48.1064 30.4138ZM48.1064 7.47585C42.5174 7.47585 38.0929 12.0169 38.0929 17.4894C38.0929 22.9619 42.6339 27.5029 48.1064 27.5029C53.6953 27.5029 58.1199 22.9619 58.1199 17.4894C58.1199 12.0169 53.5789 7.47585 48.1064 7.47585Z" fill="#FA7D00"/>
      <path d="M58.2362 59.8721C57.4212 59.8721 56.839 59.1734 56.839 58.4748V47.5298C56.839 45.434 57.8869 43.3381 59.6335 42.0573L73.8387 30.5301C75.1195 29.4822 75.4688 27.8521 74.6538 26.6877C74.0716 25.9891 73.4894 25.7562 72.9072 25.7562C72.2086 25.6398 71.51 25.8726 70.8114 26.3384L61.38 33.441C57.5376 36.2355 52.8802 37.8656 48.2227 37.8656C43.4488 37.8656 38.7914 36.3519 35.0654 33.441L25.6341 26.3384C24.7026 25.7562 24.004 25.5233 23.3053 25.5233C22.8396 25.5233 22.141 25.7562 21.5588 26.4548C20.6273 27.6192 20.9766 29.2493 22.2574 30.1808L36.5791 41.8244C38.2092 43.1052 39.2571 45.0846 39.2571 47.2969V58.242C39.2571 59.057 38.5585 59.6392 37.8599 59.6392C37.1613 59.6392 36.4626 58.9406 36.4626 58.242V47.2969C36.4626 46.0161 35.8805 44.8518 34.8325 44.1532L20.5109 32.5095C17.9493 30.6465 17.3671 27.1534 19.2301 24.7083C20.1616 23.5439 21.4424 22.7289 22.956 22.6124C24.3533 22.496 25.8669 22.8453 27.0313 23.7768L36.4626 30.8794C39.7229 33.3246 43.7981 34.7218 47.8734 34.7218C51.9487 34.7218 56.0239 33.3246 59.2842 30.8794L68.7155 23.7768C69.8799 22.8453 71.3935 22.496 72.7908 22.6124C74.3044 22.7289 75.5852 23.5439 76.5167 24.7083C78.3797 27.1534 77.7975 30.6465 75.2359 32.5095L61.38 44.1532C60.3321 44.9682 59.7499 46.1326 59.7499 47.4134V58.3584C59.7499 59.1734 59.0513 59.8721 58.2362 59.8721Z" fill="#66113F"/>
      <path d="M73.2573 75.7077C72.6751 75.7077 71.9765 75.4748 71.5107 75.1255C70.5792 74.4269 70.1135 73.3789 70.3463 72.2146L71.5107 65.3448L66.5039 60.4545C65.6889 59.6395 65.3396 58.4751 65.6889 57.4272C66.0382 56.3792 66.9697 55.5642 68.1341 55.3313L75.0038 54.2834L78.1476 47.9958C78.6133 46.9479 79.6613 46.3657 80.8256 46.3657C81.99 46.3657 83.0379 46.9479 83.5037 47.9958L86.531 54.167L93.4007 55.2149C94.5651 55.3313 95.4966 56.1464 95.8459 57.3107C96.1952 58.3587 95.9623 59.523 95.0309 60.3381L90.0241 65.2284L91.1885 72.0982C91.4213 73.2625 90.9556 74.3104 90.0241 75.0091C89.0926 75.7077 87.9282 75.8241 86.8803 75.2419L80.9421 72.0982L74.7709 75.3584C74.1887 75.5912 73.723 75.7077 73.2573 75.7077ZM80.8256 69.1872C81.2914 69.1872 81.7571 69.3037 82.2229 69.5366L88.394 72.7968L89.9077 72.4475L88.5104 72.6803L87.3461 65.8106C87.2296 64.8791 87.4625 63.8312 88.1611 63.1326L93.1679 58.2422L93.2843 56.6121L93.0514 58.0094L86.1817 56.9614C85.2502 56.845 84.3187 56.2628 83.9694 55.3313L80.9421 49.1602C80.9421 49.1602 80.9421 49.1602 80.8256 49.1602L77.6818 55.4478C77.2161 56.3792 76.401 56.9614 75.4696 57.0779L68.5998 58.1258L67.5519 59.2902L68.5998 58.2422L73.6066 63.1326C74.3052 63.8312 74.6545 64.7627 74.4216 65.8106L73.2573 72.6803L73.3737 72.7968L79.5448 69.5366C79.8941 69.3037 80.3599 69.1872 80.8256 69.1872Z" fill="#66113F"/>
      <path d="M7.70324 75.7077C7.12106 75.7077 6.42244 75.4748 5.95669 75.1255C5.0252 74.4269 4.55946 73.3789 4.79233 72.2146L5.95669 65.3448L0.949932 60.4545C0.134878 59.6395 -0.214431 58.4751 0.134878 57.4272C0.484187 56.3792 1.41568 55.5642 2.58004 55.3313L9.44979 54.2834L12.5936 47.9958C13.0593 46.9479 14.1072 46.3657 15.2716 46.3657C16.436 46.3657 17.4839 46.9479 17.9496 47.9958L20.977 54.167L27.8467 55.2149C29.0111 55.3313 29.9426 56.1464 30.2919 57.3107C30.6412 58.3587 30.4083 59.523 29.4768 60.3381L24.4701 65.2284L25.6344 72.0982C25.8673 73.2625 25.4016 74.3104 24.4701 75.0091C23.5386 75.7077 22.3742 75.8241 21.3263 75.2419L15.2716 72.0982L9.10048 75.3584C8.63473 75.5912 8.16898 75.7077 7.70324 75.7077ZM15.2716 69.1872C15.7373 69.1872 16.2031 69.3037 16.6688 69.5366L22.84 72.7968L24.3536 72.4475L22.9564 72.6803L21.792 65.8106C21.6756 64.8791 21.9085 63.8312 22.6071 63.1326L27.6139 58.2422L27.7303 56.6121L27.4974 58.0094L20.6277 56.9614C19.6962 56.845 18.7647 56.2628 18.4154 55.3313L15.388 49.1602C15.388 49.1602 15.388 49.1602 15.2716 49.1602L12.1278 55.4478C11.6621 56.3792 10.847 56.9614 9.91553 57.0779L3.04579 58.1258L1.99786 59.2902L3.04579 58.2422L8.05255 63.1326C8.75117 63.8312 9.10048 64.7627 8.8676 65.8106L7.70324 72.6803L7.81968 72.7968L13.9908 69.5366C14.3401 69.3037 14.8059 69.1872 15.2716 69.1872Z" fill="#66113F"/>
      <path d="M58.4698 90.8443C57.8876 90.8443 57.3054 90.7278 56.8397 90.3785L48.4563 85.954C48.2234 85.8375 47.9905 85.8375 47.8741 85.954L39.4907 90.3785C38.3263 90.9607 36.8127 90.9607 35.7647 90.1457C34.6004 89.3306 34.1346 88.0498 34.3675 86.6526L35.9976 77.2212C35.9976 76.9884 35.9976 76.7555 35.7647 76.6391L28.895 70.0022C27.9635 69.0707 27.6142 67.6735 27.9635 66.3927C28.4292 65.1119 29.4772 64.1804 30.8744 63.9475L40.3057 62.5503C40.5386 62.5503 40.6551 62.4338 40.7715 62.201L44.9632 53.7011C45.5454 52.4203 46.8262 51.7217 48.2234 51.7217C49.6207 51.7217 50.785 52.5367 51.4836 53.7011L55.6753 62.201C55.7918 62.4338 55.9082 62.5503 56.1411 62.5503L65.5724 63.9475C66.9697 64.1804 68.0176 65.1119 68.4833 66.3927C68.9491 67.6735 68.5998 69.0707 67.5518 70.0022L60.5657 76.6391C60.4492 76.7555 60.3328 76.9884 60.3328 77.2212L61.9629 86.6526C62.1958 88.0498 61.6136 89.3306 60.5657 90.1457C59.867 90.6114 59.1684 90.8443 58.4698 90.8443ZM48.107 83.0431C48.6892 83.0431 49.2713 83.1595 49.7371 83.5088L58.1205 87.9334C58.4698 88.0498 58.7027 87.9334 58.8191 87.9334C58.9356 87.8169 59.1684 87.5841 59.052 87.2348L57.4219 77.8034C57.189 76.6391 57.6548 75.4747 58.4698 74.6596L65.3396 68.0228C65.5724 67.7899 65.5724 67.4406 65.456 67.3242C65.456 67.2077 65.2231 66.9748 64.8738 66.8584L55.4425 65.4612C54.2781 65.3447 53.2302 64.5297 52.7644 63.4817L48.5727 54.9819C48.4563 54.6326 48.2234 54.6326 48.107 54.6326C47.9905 54.6326 47.6412 54.6326 47.5248 54.9819L43.3331 63.4817C42.8673 64.5297 41.8194 65.2283 40.6551 65.4612L31.2237 66.8584C30.8744 66.8584 30.758 67.2077 30.6415 67.3242C30.6415 67.4406 30.5251 67.7899 30.758 68.0228L37.6277 74.6596C38.4428 75.4747 38.9085 76.6391 38.6756 77.8034L37.0455 87.2348C36.9291 87.5841 37.162 87.8169 37.2784 87.9334C37.3948 88.0498 37.6277 88.1663 37.977 87.9334L46.3604 83.5088C46.9426 83.1595 47.5248 83.0431 48.107 83.0431Z" fill="#FA7D00"/>
    </svg>
  )
}